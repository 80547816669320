export default function Title() {
  return (
    <svg
      width="238"
      height="14"
      viewBox="0 0 238 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title id="title">Custody Navigator</title>
      <path
        d="M6.71945 14C5.40578 14 4.23989 13.7103 3.22179 13.131C2.20369 12.5452 1.4122 11.7208 0.847321 10.6576C0.28244 9.59436 0 8.37199 0 6.99045C0 5.61528 0.28244 4.39927 0.847321 3.34243C1.4122 2.27922 2.20369 1.45794 3.22179 0.878581C4.23989 0.29286 5.40578 0 6.71945 0C7.71128 0 8.621 0.16553 9.44861 0.49659C10.2828 0.827649 10.9988 1.30196 11.5965 1.91951C12.1942 2.53706 12.6507 3.2724 12.966 4.12551L10.0496 5.13779C9.78032 4.41837 9.35666 3.8613 8.77864 3.46658C8.20719 3.06548 7.52079 2.86494 6.71945 2.86494C5.98379 2.86494 5.34338 3.03047 4.7982 3.36153C4.25303 3.69259 3.83265 4.17008 3.53707 4.794C3.24149 5.41155 3.09371 6.1437 3.09371 6.99045C3.09371 7.84357 3.24149 8.58208 3.53707 9.206C3.83265 9.82992 4.25303 10.3074 4.7982 10.6385C5.34338 10.9695 5.98379 11.1351 6.71945 11.1351C7.52079 11.1351 8.20719 10.9377 8.77864 10.543C9.35666 10.1419 9.78032 9.58163 10.0496 8.86221L12.966 9.87449C12.6507 10.7276 12.1942 11.4629 11.5965 12.0805C10.9988 12.698 10.2828 13.1724 9.44861 13.5034C8.621 13.8345 7.71128 14 6.71945 14Z"
        fill="#065444"
      />
      <path
        d="M21.8705 14C20.0577 14 18.698 13.5734 17.7916 12.7203C16.8851 11.8672 16.4319 10.5748 16.4319 8.84311V0.343793H19.5256V8.49932C19.5256 9.1678 19.5848 9.69623 19.703 10.0846C19.8278 10.4729 20.0577 10.7658 20.3927 10.9632C20.7276 11.1542 21.2203 11.2497 21.8705 11.2497C22.5142 11.2497 23.0036 11.151 23.3386 10.9536C23.6801 10.7563 23.91 10.4666 24.0283 10.0846C24.1531 9.69623 24.2155 9.18054 24.2155 8.53752V0.343793H27.3092V8.74761C27.3092 12.2492 25.4963 14 21.8705 14Z"
        fill="#065444"
      />
      <path
        d="M36.2593 13.9427C35.2543 13.9427 34.361 13.7708 33.5794 13.427C32.7978 13.0832 32.1639 12.5962 31.6779 11.9659C31.1918 11.3292 30.8798 10.5875 30.7419 9.74079L33.7567 9.01501C33.8947 9.76626 34.187 10.3329 34.6336 10.7149C35.0803 11.0969 35.655 11.2879 36.3578 11.2879C36.8767 11.2879 37.3037 11.1701 37.6387 10.9345C37.9802 10.6926 38.151 10.3647 38.151 9.95089C38.151 9.69623 38.0722 9.4734 37.9145 9.2824C37.7569 9.08504 37.504 8.89404 37.1559 8.70941C36.8143 8.52478 36.3381 8.32106 35.7273 8.09823C34.7748 7.7417 34.0063 7.36289 33.4218 6.9618C32.8437 6.55434 32.4168 6.09277 32.1409 5.57708C31.8651 5.06139 31.7271 4.46294 31.7271 3.78172C31.7271 3.04957 31.9143 2.397 32.2887 1.82401C32.6631 1.25102 33.1886 0.805366 33.8651 0.48704C34.5482 0.162347 35.3332 0 36.2199 0C37.1854 0 38.0393 0.219645 38.7816 0.658936C39.5238 1.09186 40.1149 1.7317 40.555 2.57844L38.0919 4.03001C37.8291 3.58436 37.5303 3.24375 37.1953 3.00819C36.8669 2.77262 36.5089 2.65484 36.1214 2.65484C35.7601 2.65484 35.4514 2.75671 35.1952 2.96044C34.9456 3.1578 34.8208 3.41246 34.8208 3.72442C34.8208 3.95361 34.8931 4.16053 35.0376 4.34516C35.1821 4.52979 35.435 4.72078 35.7962 4.91815C36.1575 5.11551 36.6665 5.33515 37.3234 5.57708C38.2232 5.90814 38.9556 6.26467 39.5205 6.64666C40.0919 7.02228 40.5189 7.46476 40.8013 7.97408C41.0838 8.47704 41.225 9.07231 41.225 9.75989C41.225 10.5684 41.0181 11.291 40.6043 11.9277C40.197 12.558 39.6157 13.0514 38.8604 13.4079C38.1116 13.7644 37.2446 13.9427 36.2593 13.9427Z"
        fill="#065444"
      />
      <path d="M47.9643 3.01774H43.9445V0.343793H55.0976V3.01774H51.0974V13.6562H47.9643V3.01774Z" fill="#065444" />
      <path
        d="M64.3839 14C63.0702 14 61.9044 13.7103 60.8863 13.131C59.8682 12.5452 59.0767 11.724 58.5118 10.6671C57.9469 9.60391 57.6645 8.38472 57.6645 7.00955C57.6645 5.62801 57.9469 4.40564 58.5118 3.34243C59.0767 2.27922 59.8682 1.45794 60.8863 0.878581C61.9044 0.29286 63.0702 0 64.3839 0C65.7107 0 66.8832 0.29286 67.9013 0.878581C68.9259 1.45794 69.7174 2.27922 70.2757 3.34243C70.8406 4.39927 71.1231 5.62165 71.1231 7.00955C71.1231 8.39109 70.8373 9.61028 70.2659 10.6671C69.701 11.724 68.9062 12.5452 67.8816 13.131C66.8635 13.7103 65.6976 14 64.3839 14ZM60.7582 7.00955C60.7582 7.8563 60.906 8.59163 61.2015 9.21555C61.4971 9.83311 61.9175 10.3074 62.4627 10.6385C63.0078 10.9695 63.6483 11.1351 64.3839 11.1351C65.1196 11.1351 65.76 10.9695 66.3052 10.6385C66.8503 10.301 67.2707 9.82356 67.5663 9.206C67.8619 8.58208 68.0097 7.84993 68.0097 7.00955C68.0097 6.1628 67.8619 5.42747 67.5663 4.80355C67.2707 4.17963 66.8503 3.70214 66.3052 3.37108C65.76 3.03365 65.1196 2.86494 64.3839 2.86494C63.6483 2.86494 63.0078 3.03047 62.4627 3.36153C61.9175 3.69259 61.4971 4.17008 61.2015 4.794C60.906 5.41792 60.7582 6.15644 60.7582 7.00955Z"
        fill="#065444"
      />
      <path
        d="M74.8881 0.343793H79.0262C80.5501 0.343793 81.8506 0.608004 82.9278 1.13643C84.0116 1.66485 84.8359 2.4352 85.4008 3.44748C85.9723 4.45339 86.258 5.67258 86.258 7.10505C86.258 8.51205 85.9788 9.70578 85.4205 10.6862C84.8622 11.6603 84.0412 12.3988 82.9574 12.9018C81.8802 13.4047 80.5632 13.6562 79.0065 13.6562H74.8881V0.343793ZM78.6912 10.9059C79.6962 10.9059 80.5172 10.7976 81.1544 10.5812C81.7981 10.3647 82.2907 9.97317 82.6323 9.40655C82.9738 8.83356 83.1446 8.0282 83.1446 6.99045C83.1446 5.99091 82.9804 5.20782 82.652 4.6412C82.3235 4.06821 81.8572 3.66712 81.2529 3.43793C80.6486 3.20873 79.8867 3.09413 78.9671 3.09413H77.9818V10.9059H78.6912Z"
        fill="#065444"
      />
      <path
        d="M92.6462 8.40382L87.8184 0.343793H91.3653L94.124 5.42428L96.9813 0.343793H100.489L95.7399 8.40382V13.6562H92.6462V8.40382Z"
        fill="#065444"
      />
      <path
        d="M114.257 0.343793H117.409L122.592 8.46112V0.343793H125.685V13.6562H122.513L117.35 5.50068V13.6562H114.257V0.343793Z"
        fill="#065444"
      />
      <path
        d="M134.577 0.343793H136.843L142.538 13.6562H139.169L138.292 11.4025H133.119L132.232 13.6562H128.863L134.577 0.343793ZM137.247 8.72851L135.72 4.8131L134.173 8.72851H137.247Z"
        fill="#065444"
      />
      <path
        d="M142.842 0.343793H146.251L149.522 8.68077L152.734 0.343793H156.162L150.645 13.6562H148.379L142.842 0.343793Z"
        fill="#065444"
      />
      <path d="M159.347 0.343793H162.441V13.6562H159.347V0.343793Z" fill="#065444" />
      <path
        d="M172.735 14C171.48 14 170.357 13.7071 169.365 13.1214C168.38 12.5357 167.608 11.7112 167.05 10.648C166.492 9.58481 166.212 8.36562 166.212 6.99045C166.212 5.61528 166.495 4.39927 167.06 3.34243C167.625 2.27922 168.416 1.45794 169.434 0.878581C170.452 0.29286 171.618 0 172.932 0C173.924 0 174.833 0.16553 175.661 0.49659C176.495 0.827649 177.211 1.30196 177.809 1.91951C178.407 2.53706 178.863 3.2724 179.178 4.12551L176.262 5.13779C175.993 4.41837 175.569 3.8613 174.991 3.46658C174.42 3.06548 173.733 2.86494 172.932 2.86494C172.196 2.86494 171.556 3.03047 171.011 3.36153C170.466 3.69259 170.045 4.17008 169.75 4.794C169.454 5.41155 169.306 6.1437 169.306 6.99045C169.306 7.84357 169.454 8.58208 169.75 9.206C170.045 9.82992 170.466 10.3074 171.011 10.6385C171.556 10.9695 172.196 11.1351 172.932 11.1351C173.681 11.1351 174.357 10.9695 174.962 10.6385C175.572 10.301 175.999 9.73443 176.242 8.93861H173.129V6.26467H179.178V13.6562H176.459L176.223 12.6344C175.822 13.0737 175.326 13.4111 174.735 13.6467C174.144 13.8822 173.477 14 172.735 14Z"
        fill="#065444"
      />
      <path
        d="M187.854 0.343793H190.12L195.815 13.6562H192.446L191.569 11.4025H186.396L185.509 13.6562H182.14L187.854 0.343793ZM190.524 8.72851L188.997 4.8131L187.45 8.72851H190.524Z"
        fill="#065444"
      />
      <path d="M200.179 3.01774H196.159V0.343793H207.312V3.01774H203.312V13.6562H200.179V3.01774Z" fill="#065444" />
      <path
        d="M216.599 14C215.285 14 214.119 13.7103 213.101 13.131C212.083 12.5452 211.292 11.724 210.727 10.6671C210.162 9.60391 209.879 8.38472 209.879 7.00955C209.879 5.62801 210.162 4.40564 210.727 3.34243C211.292 2.27922 212.083 1.45794 213.101 0.878581C214.119 0.29286 215.285 0 216.599 0C217.926 0 219.098 0.29286 220.116 0.878581C221.141 1.45794 221.932 2.27922 222.491 3.34243C223.056 4.39927 223.338 5.62165 223.338 7.00955C223.338 8.39109 223.052 9.61028 222.481 10.6671C221.916 11.724 221.121 12.5452 220.096 13.131C219.078 13.7103 217.913 14 216.599 14ZM212.973 7.00955C212.973 7.8563 213.121 8.59163 213.416 9.21555C213.712 9.83311 214.132 10.3074 214.678 10.6385C215.223 10.9695 215.863 11.1351 216.599 11.1351C217.335 11.1351 217.975 10.9695 218.52 10.6385C219.065 10.301 219.486 9.82356 219.781 9.206C220.077 8.58208 220.225 7.84993 220.225 7.00955C220.225 6.1628 220.077 5.42747 219.781 4.80355C219.486 4.17963 219.065 3.70214 218.52 3.37108C217.975 3.03365 217.335 2.86494 216.599 2.86494C215.863 2.86494 215.223 3.03047 214.678 3.36153C214.132 3.69259 213.712 4.17008 213.416 4.794C213.121 5.41792 212.973 6.15644 212.973 7.00955Z"
        fill="#065444"
      />
      <path
        d="M227.103 0.343793H231.931C235.11 0.343793 236.699 1.6553 236.699 4.27831C236.699 5.20146 236.502 5.97181 236.108 6.58936C235.721 7.20055 235.149 7.65575 234.394 7.95498L238 13.6562H234.414L231.241 8.36562H230.197V13.6562H227.103V0.343793ZM231.616 5.69168C232.01 5.69168 232.348 5.65985 232.63 5.59618C232.913 5.53252 233.146 5.402 233.33 5.20464C233.514 5.00091 233.606 4.70487 233.606 4.31651C233.606 3.93452 233.514 3.65121 233.33 3.46658C233.146 3.27558 232.916 3.15462 232.64 3.10368C232.364 3.04638 232.023 3.01774 231.616 3.01774H230.197V5.69168H231.616Z"
        fill="#065444"
      />
    </svg>
  );
}
