export default function CustodyNavigatorIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Custody Navigator</title>
      <circle cx="11.4474" cy="11.4474" r="11.4474" fill="#FD8E3F" />
      <path
        d="M13.4211 12.0928C13.4211 11.0825 14.2401 10.2635 15.2503 10.2635H28.1708C29.181 10.2635 30 11.0825 30 12.0928V28.1711C30 29.1814 29.181 30.0003 28.1707 30.0003H15.2503C14.2401 30.0003 13.4211 29.1814 13.4211 28.1711V12.0928Z"
        fill="#8EA5DB"
      />
      <path
        d="M13.4211 22.7254C18.8028 21.7902 22.8948 17.0967 22.8948 11.4476C22.8948 11.0478 22.8743 10.6527 22.8343 10.2635H14.8845C14.0763 10.2635 13.4211 10.9187 13.4211 11.7269V22.7254Z"
        fill="#006750"
      />
    </svg>
  );
}
