import { OvernightStats } from '~/interfaces/OvernightStats';

export default function OvernightPercent({ overnightStats }: { overnightStats: Array<OvernightStats> }) {
  return (
    <div className="flex flex-col space-y-2 p-4">
      <h5 className="text-brown text-sm font-medium">Overnights breakdown</h5>
      <span className="flex w-full items-center space-x-1">
        {overnightStats.map(({ guardianId, overnightPercent }) => {
          return (
            <span
              style={{ width: overnightPercent }}
              className={`text-green-primary-text flex h-8 items-center border px-2 text-base font-medium ${guardianId === 0 ? 'border-orange bg-orange-light-20 justify-start rounded-l-full rounded-r-[100rem]' : 'border-blue bg-blue-light-20 justify-end rounded-l-[100rem] rounded-r-full'}`}
              key={guardianId}
            >
              {overnightPercent}
            </span>
          );
        })}
      </span>
    </div>
  );
}
