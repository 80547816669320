export default function PieIcon7030() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.0785"
        cy="15.0598"
        r="13"
        fill="#E77F7E"
        fillOpacity="0.25"
      />
      <path
        d="M15.0785 2.05987C12.681 2.05987 10.3302 2.72286 8.286 3.97554C6.24181 5.22823 4.58384 7.02181 3.4954 9.15799C2.40696 11.2942 1.93045 13.6897 2.11856 16.0798C2.30666 18.4699 3.15205 20.7615 4.56126 22.7011C5.97047 24.6407 7.8886 26.1528 10.1036 27.0703C12.3186 27.9878 14.7442 28.2749 17.1121 27.8998C19.4801 27.5248 21.6982 26.5022 23.5213 24.9451C25.3444 23.3881 26.7014 21.3572 27.4422 19.0771L15.0785 15.0599L15.0785 2.05987Z"
        fill="#E77F7E"
      />
    </svg>
  );
}
