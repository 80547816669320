import { PageRoutes } from '~/constants/routes';
import { DropdownMenuItem } from '../ui/dropdown-menu';
import { Link } from '@remix-run/react';
import ProfileIcon from '../icons/ProfileIcon';
import CaretRightIcon from '../icons/CaretRightIcon';
import SecurityIcon from '../icons/SecurityIcon';
import CalendarIcon from '../icons/CalendarIcon';
import CalendarCheckIcon from '../icons/CalendarCheckIcon';
import SummaryIcon from '../icons/SummaryIcon';
import { Button } from '../ui/button';
import HolidaysIcon from '../icons/HolidaysIcon';
import { cn } from '~/lib/utils';

export default function AuthedMenu({
  onCloseMenu,
  openSummary,
  scheduleExists,
}: {
  onCloseMenu: () => void;
  openSummary: () => void;
  scheduleExists: boolean;
}) {
  const menuItems = [
    { route: PageRoutes.PROFILE, icon: <ProfileIcon />, label: 'Profile', extraClassName: 'pt-4' },
    { route: PageRoutes.SECURITY, icon: <SecurityIcon />, label: 'Security' },
    ...(scheduleExists ? [{ route: PageRoutes.CALENDAR_PREVIEW, icon: <CalendarIcon />, label: 'My calendar' }] : []),
    { route: PageRoutes.TEMPLATES, icon: <CalendarCheckIcon />, label: 'Choose new template' },
    ...(scheduleExists
      ? [
          { onClick: () => openSummary(), icon: <SummaryIcon />, label: 'Summary' },
          { route: PageRoutes.HOLIDAYS_LIST, icon: <HolidaysIcon />, label: 'Holidays', extraClassName: 'pb-4' },
        ]
      : []),
  ];

  return (
    <>
      {menuItems.map(({ icon, label, extraClassName, route, onClick }) => {
        const content = (
          <span className="flex items-center justify-start space-x-2">
            <span className="relative top-[-1px]">{icon}</span>
            <span className="text-xl font-semibold text-green-primary-text">{label}</span>
          </span>
        );

        return (
          <DropdownMenuItem key={label} onClick={onCloseMenu} className="flex w-full focus:bg-transparent">
            {onClick ? (
              <Button type="button" onClick={onClick} className="flex w-full items-center justify-between px-0 py-2">
                {content}
                <CaretRightIcon />
              </Button>
            ) : (
              <Link to={route} className={cn('flex w-full items-center justify-between py-2', extraClassName)}>
                {content}
                <CaretRightIcon />
              </Link>
            )}
          </DropdownMenuItem>
        );
      })}
      <div className="w-full border-t border-dashed border-taupe pt-4" />
    </>
  );
}
