import { categoryIconAndRoute } from '~/constants/categoryIconAndRoute';
import { TimesplitId } from '~/types/TimesplitId';

export default function TimesplitAndPattern({
  timesplitId,
  templateName,
}: {
  timesplitId: TimesplitId;
  templateName: string;
}) {
  return (
    <div className="flex items-center justify-start px-4 pt-4">
      <div className="flex items-center justify-start">
        {categoryIconAndRoute[timesplitId as TimesplitId].icon}
        <span className="text-green-primary-text pl-2 text-lg font-medium">{templateName}</span>
      </div>
    </div>
  );
}
