export default function HamburgerIcon() {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.375 0.5H12.625V1.8125H0.375V0.5ZM0.375 4.875H12.625V6.1875H0.375V4.875ZM12.625 9.25V10.5625H0.375V9.25H12.625Z"
        fill="#065444"
      />
    </svg>
  );
}
