export default function HolidaysIcon() {
  return (
    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2656 11.125L11.7656 18H22H23.25V20.5H22H2H0.75V18H2H9.10938L11.9219 10.2656L14.2656 11.125ZM18.9922 11.125C19.7344 9.01562 20.125 6.82812 20.0469 4.60156V4.36719C20.0078 4.01562 19.9688 3.70312 19.8906 3.39062C22.4688 5.77344 23.6406 9.40625 22.8203 12.9219L18.875 11.4766L18.9922 11.125ZM18.7969 4.40625V4.64062C18.8359 6.71094 18.5234 8.74219 17.8203 10.6953L17.7031 11.0469L9.46094 8.07812L9.57812 7.6875C10.3203 5.77344 11.375 3.97656 12.7422 2.41406L12.8984 2.25781C13.8359 1.24219 15.2422 0.851562 16.5312 1.32031C16.5703 1.32031 16.6094 1.35938 16.6484 1.35938C17.8984 1.86719 18.7188 3.03906 18.7969 4.40625ZM4.1875 6.125C5.86719 2.84375 9.22656 0.8125 12.7812 0.695312C12.4688 0.890625 12.2344 1.16406 11.9609 1.39844L11.8047 1.59375C10.3203 3.23438 9.1875 5.1875 8.40625 7.25781L8.28906 7.64844L4.1875 6.125Z"
        fill="#065444"
      />
    </svg>
  );
}
