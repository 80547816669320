export default function PieIcon8020() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.0785"
        cy="15.0598"
        r="13"
        fill="#FD8E3F"
        fillOpacity="0.25"
      />
      <path
        d="M15.0785 2.05987C12.3332 2.05987 9.6583 2.92899 7.43728 4.54265C5.21626 6.15632 3.5631 8.43169 2.71475 11.0427C1.86639 13.6536 1.86639 16.4661 2.71475 19.0771C3.5631 21.6881 5.21625 23.9634 7.43727 25.5771C9.65829 27.1908 12.3332 28.0599 15.0785 28.0599C17.8238 28.0599 20.4987 27.1908 22.7197 25.5771C24.9407 23.9634 26.5939 21.6881 27.4422 19.0771C28.2906 16.4661 28.2906 13.6536 27.4422 11.0427L15.0785 15.0599L15.0785 2.05987Z"
        fill="#FD8E3F"
      />
    </svg>
  );
}
