import { Link } from '@remix-run/react';
import { PageRoutes } from '~/constants/routes';

export default function FirstOvernight({
  parentName,
  coparentName,
  firstOvernight,
}: {
  parentName: string | undefined;
  coparentName: string | undefined;
  firstOvernight: string | undefined;
}) {
  const parentClasses = 'bg-orange-light-20 border-orange';
  const coparentClasses = 'bg-blue-light-20 border-blue';

  return (
    <>
      <div className="flex items-center justify-between px-4 pt-4">
        <h5 className="text-brown text-sm font-medium">Parents</h5>
        <Link className="text-brown text-xs font-medium uppercase underline" to={PageRoutes.PARENT_INFO}>
          Edit
        </Link>
      </div>
      <div className="flex w-full items-center justify-between gap-x-4 px-4 pt-2">
        {[parentName, coparentName].map((parent, index) => (
          <div
            key={`${parent}-${index}`}
            className={`flex h-14 w-1/2 flex-col items-center justify-center rounded-lg border ${index ? coparentClasses : parentClasses}`}
          >
            {parent === firstOvernight ? (
              <span className="text-brown text-[8px] font-bold uppercase">
                1<span>st</span> Overnight
              </span>
            ) : null}
            <span className="text-green-primary-text text-base font-medium">{parent}</span>
          </div>
        ))}
      </div>
    </>
  );
}
