import { Link } from '@remix-run/react';
import CustodyNavigatorIcon from '../icons/CustodyNavigatorIcon';
import Title from '../images/Title';
import HamburgerIcon from '../icons/HamburgerIcon';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { PageRoutes } from '~/constants/routes';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import React from 'react';
import CloseIcon from '../icons/CloseIcon';
import { Dialog, DialogContent } from '../ui/dialog';
import ScheduleSummary from '../scheduleSummary/ScheduleSummary';
import CaretRightIcon from '../icons/CaretRightIcon';
import CalendarCheckIcon from '../icons/CalendarCheckIcon';
import MenuTermsAndPrivacy from './MenuTermsAndPrivacy';
import AuthActions from './AuthActions';
import AuthedMenu from './AuthedMenu';
import useIsCalendarRoute from '~/hooks/useIsCalendarRoute';

interface Props {
  isAuthenticated?: boolean;
  onLogout: () => void;
  userData: { scheduleExists: boolean; name: string };
}

export default function NavBar({ isAuthenticated = false, onLogout, userData }: Props) {
  const isCalendarOrHolidayRoute = useIsCalendarRoute();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isSummaryOpen, setIsSummaryOpen] = React.useState(false);

  return (
    <>
      <nav className="flex h-20 items-center justify-center px-4">
        <Link to="/" className="flex items-center justify-center">
          <CustodyNavigatorIcon />
        </Link>
        <span className="flex grow items-center justify-center">
          <Title />
        </span>
        <DropdownMenu modal={true} open={isMenuOpen} onOpenChange={(newOpen) => setIsMenuOpen(newOpen)}>
          <DropdownMenuTrigger className="flex cursor-pointer items-center justify-center">
            <span
              className={`flex h-7 w-7 items-center justify-center rounded-full ${isMenuOpen ? 'bg-green-dark' : 'bg-beige'}`}
            >
              {isMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
            </span>
          </DropdownMenuTrigger>

          <DropdownMenuPortal>
            <div className="fixed left-0 top-20 z-10 h-full w-full bg-backdrop-dropdown backdrop-blur-sm">
              <DropdownMenuContent className="nav__dropdown flex w-[366px] flex-col items-center justify-center rounded-2xl border border-taupe-light-75 bg-neutral-linen p-4">
                {isAuthenticated ? (
                  <>
                    <DropdownMenuLabel className="flex w-full justify-start text-xl font-semibold text-green-primary-text">
                      Hi{userData.name ? `, ${userData.name}` : null}!
                    </DropdownMenuLabel>
                    <div className="w-full border-b border-dashed border-taupe pt-2" />
                  </>
                ) : null}
                {isAuthenticated ? (
                  <AuthedMenu
                    onCloseMenu={() => setIsMenuOpen(false)}
                    openSummary={() => setIsSummaryOpen(true)}
                    scheduleExists={userData.scheduleExists}
                  />
                ) : (
                  <>
                    <DropdownMenuItem onClick={() => setIsMenuOpen(false)} className="flex w-full">
                      <Link to={PageRoutes.TEMPLATES} className="flex w-full items-center justify-between pb-4">
                        <span className="flex items-center justify-start space-x-2">
                          <span className="relative top-[-1px]">
                            <CalendarCheckIcon />
                          </span>
                          <span className="text-xl font-semibold text-green-primary-text">Schedule templates</span>
                        </span>
                        <CaretRightIcon />
                      </Link>
                    </DropdownMenuItem>
                    <div className="w-full border-t border-dashed border-taupe pt-4" />
                  </>
                )}
                <AuthActions
                  isAuthenticated={isAuthenticated}
                  onCloseMenu={() => setIsMenuOpen(false)}
                  onLogout={onLogout}
                />
                <MenuTermsAndPrivacy />
              </DropdownMenuContent>
            </div>
          </DropdownMenuPortal>
        </DropdownMenu>
      </nav>
      {isCalendarOrHolidayRoute ? <div className="w-full border-b border-dashed border-taupe" /> : null}

      <Dialog open={isSummaryOpen} onOpenChange={() => setIsSummaryOpen(false)}>
        <div className="m-0 h-full w-full bg-beige-light-20">
          <DialogContent
            className="flex max-w-96 flex-col gap-4 rounded-[1rem] border border-brown"
            closeSvgStyles="text-green-dark"
            closeButtonStyles="top-4 bg-beige p-1 rounded-full"
          >
            <ScheduleSummary />
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
}
