import OFWLogoIcon from '../icons/OFWLogoIcon';

export default function MenuTermsAndPrivacy() {
  return (
    <div className="flex flex-col items-center justify-center pt-4">
      <p className="text-brown-dark flex flex-wrap items-center justify-center text-xs font-light">
        <span className="flex pr-1">Made with</span> <OFWLogoIcon />
      </p>
      <ul className="flex items-center justify-center">
        <li>
          <a
            className="text-green-dark text-xs font-light underline"
            href="https://legal.custodynavigator.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </li>
        <span className="flex items-center justify-center px-1 text-xs">{'|'}</span>
        <li>
          <a
            href="https://legal.custodynavigator.com/terms"
            target="_blank"
            rel="noreferrer"
            className="text-green-dark text-xs font-light underline"
          >
            Terms
          </a>
        </li>
      </ul>
    </div>
  );
}
