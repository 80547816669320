export default function CloseIcon() {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.48242 1.88867L5.9668 5.375L9.45312 8.86133L9.95117 9.35938L8.98438 10.3555L8.48633 9.85742L5 6.37109L1.51367 9.85742L1.01562 10.3555L0.0195312 9.35938L0.517578 8.86133L4.00391 5.375L0.517578 1.88867L0.0195312 1.39062L1.01562 0.394531L1.51367 0.892578L5 4.4082L8.48633 0.921875L8.98438 0.423828L9.98047 1.39062L9.48242 1.88867Z"
        fill="#F8F2EB"
      />
    </svg>
  );
}
