export default function OFWLogoIcon() {
  return (
    <svg width="128" height="15" viewBox="0 0 128 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>OurFamilyWizard</title>
      <g clipPath="url(#clip0_1389_1532)">
        <path
          d="M28.125 4.74268C27.9096 4.14605 27.5971 3.61009 27.1956 3.15335C26.794 2.69492 26.2953 2.32414 25.714 2.0511C25.1311 1.77807 24.4689 1.63818 23.7451 1.63818C23.0213 1.63818 22.3591 1.77639 21.7762 2.0511C21.1933 2.32582 20.6946 2.69661 20.2947 3.15335C19.8931 3.61178 19.5806 4.14605 19.3653 4.74268C19.1499 5.33931 19.0398 5.96459 19.0398 6.60167C19.0398 7.23875 19.1499 7.86403 19.3653 8.46065C19.5806 9.05728 19.8931 9.59324 20.2947 10.05C20.6962 10.5084 21.1949 10.8792 21.7762 11.1522C22.3591 11.4253 23.0213 11.5652 23.7451 11.5652C24.4689 11.5652 25.1311 11.4269 25.714 11.1522C26.2969 10.8792 26.7956 10.5067 27.1956 10.05C27.5971 9.59155 27.9096 9.05728 28.125 8.46065C28.3403 7.86403 28.4504 7.23875 28.4504 6.60167C28.4504 5.96459 28.3403 5.33931 28.125 4.74268ZM26.8442 6.60167C26.8442 7.05504 26.773 7.49998 26.6321 7.92133C26.4912 8.34436 26.284 8.72526 26.0152 9.05223C25.7464 9.38088 25.4177 9.64717 25.0372 9.84099C24.6583 10.0365 24.2244 10.1359 23.7451 10.1359C23.2658 10.1359 22.8319 10.0365 22.453 9.84099C22.0741 9.64549 21.7454 9.38088 21.4767 9.05223C21.2079 8.72358 21.0006 8.34268 20.8597 7.92133C20.7189 7.49998 20.6476 7.05504 20.6476 6.60167C20.6476 6.1483 20.7189 5.70335 20.8597 5.282C21.0006 4.85897 21.2079 4.47976 21.4767 4.1511C21.7454 3.82245 22.0741 3.55616 22.453 3.36234C22.8335 3.16684 23.2675 3.0674 23.7451 3.0674C24.2228 3.0674 24.6567 3.16684 25.0372 3.36234C25.4177 3.55785 25.7464 3.82414 26.0152 4.1511C26.284 4.47976 26.4912 4.86066 26.6321 5.282C26.773 5.70504 26.8442 6.1483 26.8442 6.60167Z"
          fill="#122845"
        />
        <path
          d="M35.5895 5.01573V11.0275C35.5895 11.2045 35.4519 11.3494 35.2802 11.3494H34.4026C34.2326 11.3494 34.0934 11.2062 34.0934 11.0275V10.4208C33.8926 10.7832 33.6125 11.0562 33.2563 11.2416C32.9 11.427 32.4839 11.5197 32.0079 11.5197C31.5318 11.5197 31.1513 11.4388 30.8356 11.277C30.5199 11.1152 30.2705 10.8961 30.0875 10.6197C29.9321 10.3719 29.8252 10.0972 29.7653 9.79045C29.7054 9.4854 29.6763 9.1045 29.6763 8.64776V5.01405C29.6763 4.83708 29.8139 4.69214 29.9855 4.69214H30.8631C31.0331 4.69214 31.1724 4.8354 31.1724 5.01405V8.13371C31.1724 8.41854 31.1951 8.68652 31.2404 8.93259C31.2857 9.18034 31.3618 9.3927 31.4671 9.56798C31.5723 9.74495 31.7132 9.88483 31.8929 9.98933C32.071 10.0938 32.2929 10.1461 32.5584 10.1461C33.0798 10.1461 33.4668 9.95394 33.7177 9.56798C33.9687 9.18203 34.095 8.70506 34.095 8.13371V5.01405C34.095 4.83708 34.2326 4.69214 34.4043 4.69214H35.2819C35.4519 4.69214 35.5911 4.8354 35.5911 5.01405"
          fill="#122845"
        />
        <path
          d="M43.2352 11.3477H44.2083C44.3751 11.3477 44.5095 11.2079 44.5095 11.0343V7.46629H46.7423C46.9091 7.46629 47.0451 7.32471 47.0451 7.15112V6.31011C47.0451 6.13651 46.9091 5.99494 46.7423 5.99494H44.5095V3.33539H48.1688C48.3356 3.33539 48.4716 3.19381 48.4716 3.02022V2.18089C48.4716 2.0073 48.3356 1.86572 48.1688 1.86572H43.2352C43.0684 1.86572 42.9324 2.0073 42.9324 2.18089V11.0309C42.9324 11.2045 43.0668 11.3444 43.2335 11.3444"
          fill="#122845"
        />
        <path
          d="M48.1169 8.02234C48.1169 8.49762 48.1963 8.94425 48.3566 9.36391C48.5169 9.78357 48.7403 10.1544 49.0285 10.4763C49.3167 10.7999 49.6616 11.0544 50.0632 11.2398C50.4647 11.4251 50.9084 11.5178 51.3925 11.5178C51.7666 11.5178 52.1406 11.4235 52.5098 11.233C52.8806 11.0426 53.1655 10.7813 53.3663 10.4476V11.0341C53.3663 11.206 53.5007 11.3459 53.6659 11.3459H54.5596C54.7248 11.3459 54.8592 11.206 54.8592 11.0341V5.00885C54.8592 4.83694 54.7248 4.69706 54.5596 4.69706H53.6659C53.5007 4.69706 53.3663 4.83694 53.3663 5.00885V5.59537C53.1655 5.26166 52.8789 5.00043 52.5098 4.80998C52.139 4.61953 51.7666 4.52515 51.3925 4.52515C50.9084 4.52515 50.4647 4.61784 50.0632 4.80324C49.6616 4.98863 49.3167 5.24312 49.0285 5.56672C48.7403 5.89031 48.5169 6.26447 48.3566 6.68751C48.1963 7.11054 48.1169 7.55548 48.1169 8.02234ZM49.741 7.20829C49.8268 6.95211 49.9531 6.72796 50.1182 6.53751C50.2834 6.34706 50.4793 6.19537 50.7076 6.08076C50.9359 5.96616 51.1918 5.90885 51.4751 5.90885C51.7585 5.90885 52.0175 5.96616 52.2491 6.08076C52.4822 6.19537 52.6814 6.34706 52.8449 6.53751C53.0101 6.72796 53.1364 6.95211 53.2287 7.20829C53.3193 7.46447 53.3663 7.73582 53.3663 8.02234C53.3663 8.30885 53.321 8.57852 53.2287 8.8347C53.1364 9.09088 53.0101 9.31503 52.8449 9.50548C52.6798 9.69593 52.4822 9.84762 52.2491 9.96222C52.0159 10.0768 51.7568 10.1341 51.4751 10.1341C51.1934 10.1341 50.9359 10.0768 50.7076 9.96222C50.4793 9.84762 50.2834 9.69593 50.1182 9.50548C49.9531 9.31503 49.8284 9.09088 49.741 8.8347C49.6535 8.57852 49.6114 8.30717 49.6114 8.02234C49.6114 7.73751 49.6552 7.46616 49.741 7.20829Z"
          fill="#122845"
        />
        <path
          d="M56.9252 11.3477H57.819C57.9841 11.3477 58.1185 11.2078 58.1185 11.0359V7.96679C58.1185 7.56735 58.1557 7.23701 58.2286 6.97578C58.3015 6.71454 58.4002 6.50218 58.5233 6.34039C58.6464 6.17859 58.7905 6.06398 58.9556 5.99825C59.1208 5.93083 59.2989 5.89881 59.4899 5.89881C59.9093 5.89881 60.2089 6.0505 60.387 6.35555C60.5651 6.66061 60.6541 7.07859 60.6541 7.61117V11.0376C60.6541 11.2095 60.7885 11.3494 60.9537 11.3494H61.8475C62.0126 11.3494 62.147 11.2095 62.147 11.0376V7.96848C62.147 7.56904 62.181 7.2387 62.2506 6.97746C62.3186 6.71623 62.4158 6.50387 62.5388 6.34207C62.6619 6.18027 62.8044 6.06567 62.9631 5.99994C63.1234 5.93252 63.2999 5.9005 63.4909 5.9005C63.9119 5.9005 64.2147 6.05555 64.4025 6.36398C64.5903 6.67409 64.6826 7.09376 64.6826 7.62634V11.0393C64.6826 11.2112 64.817 11.3511 64.9822 11.3511H65.876C66.0411 11.3511 66.1755 11.2112 66.1755 11.0393V7.14263C66.1755 6.82915 66.1464 6.51735 66.0865 6.20724C66.0266 5.89881 65.9132 5.62072 65.7432 5.37297C65.5732 5.12522 65.3384 4.92297 65.0372 4.76623C64.7361 4.60949 64.3475 4.53027 63.8714 4.53027C63.3954 4.53027 63.0116 4.64488 62.6651 4.87241C62.317 5.10162 62.066 5.3814 61.9106 5.71511C61.773 5.3814 61.5495 5.10162 61.2387 4.87241C60.9278 4.64488 60.4809 4.53027 59.8947 4.53027C59.4738 4.53027 59.1062 4.63645 58.7905 4.8505C58.4747 5.06454 58.2497 5.32409 58.112 5.62746V5.0123C58.112 4.84039 57.9776 4.7005 57.8125 4.7005H56.9187C56.7535 4.7005 56.6191 4.84039 56.6191 5.0123V11.0376C56.6191 11.2095 56.7535 11.3494 56.9187 11.3494"
          fill="#122845"
        />
        <path
          d="M71.6371 11.3478H72.5293C72.696 11.3478 72.8304 11.2079 72.8304 11.0343V2.18091C72.8304 2.00732 72.696 1.86743 72.5293 1.86743H71.6371C71.4703 1.86743 71.3359 2.00732 71.3359 2.18091V11.0343C71.3359 11.2079 71.4703 11.3478 71.6371 11.3478Z"
          fill="#122845"
        />
        <path
          d="M84.3072 11.0579C84.3687 11.2315 84.5274 11.3478 84.7039 11.3478H85.2431C85.4244 11.3478 85.5847 11.2281 85.643 11.0495L87.7658 4.56575L89.8756 11.0478C89.9338 11.2264 90.0941 11.3461 90.2755 11.3461H90.8147C90.9928 11.3461 91.1515 11.2298 91.2114 11.0562L94.2813 2.28372C94.3526 2.08148 94.2085 1.86743 94.0012 1.86743H93.0686C92.8888 1.86743 92.7285 1.98541 92.6686 2.16238L90.5605 8.43709L88.4393 2.16238C88.3794 1.98709 88.2208 1.86912 88.041 1.86912H87.4743C87.2946 1.86912 87.1343 1.98709 87.076 2.16238L84.9549 8.43709L82.8467 2.16238C82.7868 1.98541 82.6265 1.86743 82.4468 1.86743H81.5141C81.3069 1.86743 81.1628 2.08148 81.234 2.28372L84.304 11.0562L84.3072 11.0579Z"
          fill="#122845"
        />
        <path
          d="M98.4765 6.08071H101.163L98.2417 11.0172C98.1575 11.1622 98.2579 11.3459 98.4214 11.3459H103.302C103.468 11.3459 103.604 11.2043 103.604 11.0307V10.2757C103.604 10.1021 103.468 9.96048 103.302 9.96048H100.547L103.457 5.02396C103.54 4.87902 103.439 4.69531 103.277 4.69531H98.4781C98.3113 4.69531 98.1753 4.83689 98.1753 5.01048V5.76554C98.1753 5.93913 98.3113 6.08071 98.4781 6.08071"
          fill="#122845"
        />
        <path
          d="M105.159 10.4763C105.447 10.7999 105.792 11.0544 106.194 11.2398C106.595 11.4252 107.039 11.5179 107.523 11.5179C107.897 11.5179 108.269 11.4235 108.64 11.233C109.011 11.0426 109.296 10.7813 109.497 10.4476V11.0325C109.497 11.2061 109.633 11.3476 109.799 11.3476H110.688C110.855 11.3476 110.991 11.2061 110.991 11.0325V5.01225C110.991 4.83865 110.855 4.69708 110.688 4.69708H109.799C109.633 4.69708 109.497 4.83865 109.497 5.01225V5.59708C109.296 5.26337 109.009 5.00214 108.64 4.81169C108.269 4.62124 107.897 4.52686 107.523 4.52686C107.039 4.52686 106.595 4.61955 106.194 4.80494C105.79 4.99034 105.447 5.24483 105.159 5.56843C104.871 5.89202 104.647 6.26618 104.487 6.68921C104.327 7.11225 104.247 7.55719 104.247 8.02405C104.247 8.4909 104.327 8.94596 104.487 9.36562C104.647 9.78528 104.871 10.1561 105.159 10.478M105.871 7.21C105.957 6.95382 106.083 6.72966 106.249 6.53922C106.414 6.34877 106.61 6.19708 106.838 6.08247C107.066 5.96787 107.322 5.91056 107.605 5.91056C107.889 5.91056 108.148 5.96787 108.379 6.08247C108.613 6.19708 108.812 6.34877 108.975 6.53922C109.14 6.72966 109.268 6.95382 109.359 7.21C109.45 7.46618 109.497 7.73753 109.497 8.02405C109.497 8.31056 109.451 8.58023 109.359 8.83641C109.267 9.09259 109.14 9.31674 108.975 9.50719C108.812 9.69764 108.613 9.84933 108.379 9.96393C108.146 10.0785 107.889 10.1358 107.605 10.1358C107.322 10.1358 107.066 10.0785 106.838 9.96393C106.61 9.84933 106.414 9.69764 106.249 9.50719C106.083 9.31674 105.959 9.09259 105.871 8.83641C105.784 8.58023 105.74 8.30888 105.74 8.02405C105.74 7.73921 105.784 7.46787 105.871 7.21Z"
          fill="#122845"
        />
        <path
          d="M118.279 10.4764C118.567 10.8 118.912 11.0545 119.314 11.2399C119.715 11.4253 120.159 11.518 120.643 11.518C121.017 11.518 121.389 11.4236 121.76 11.2332C122.131 11.0427 122.416 10.7815 122.617 10.4478V11.0326C122.617 11.2062 122.753 11.3478 122.92 11.3478H123.809C123.975 11.3478 124.111 11.2062 124.111 11.0326V2.1826C124.111 2.009 123.975 1.86743 123.809 1.86743H122.92C122.753 1.86743 122.617 2.009 122.617 2.1826V5.59721C122.416 5.2635 122.129 5.00226 121.76 4.81181C121.389 4.62136 121.017 4.52698 120.643 4.52698C120.159 4.52698 119.715 4.61968 119.314 4.80507C118.911 4.99046 118.567 5.24496 118.279 5.56856C117.991 5.89215 117.767 6.26631 117.607 6.68934C117.447 7.11238 117.367 7.55732 117.367 8.02417C117.367 8.49103 117.447 8.94608 117.607 9.36575C117.767 9.78541 117.991 10.1562 118.279 10.4781M118.991 7.21013C119.077 6.95395 119.204 6.72979 119.369 6.53934C119.534 6.34889 119.73 6.19721 119.958 6.0826C120.186 5.96799 120.442 5.91069 120.726 5.91069C121.009 5.91069 121.268 5.96799 121.5 6.0826C121.733 6.19721 121.932 6.34889 122.095 6.53934C122.261 6.72979 122.387 6.95395 122.479 7.21013C122.57 7.46631 122.617 7.73766 122.617 8.02417C122.617 8.31069 122.571 8.58035 122.479 8.83653C122.387 9.09271 122.261 9.31687 122.095 9.50732C121.93 9.69777 121.733 9.84945 121.5 9.96406C121.266 10.0787 121.009 10.136 120.726 10.136C120.442 10.136 120.186 10.0787 119.958 9.96406C119.73 9.84945 119.534 9.69777 119.369 9.50732C119.204 9.31687 119.079 9.09271 118.991 8.83653C118.904 8.58035 118.86 8.309 118.86 8.02417C118.86 7.73934 118.904 7.46799 118.991 7.21013Z"
          fill="#122845"
        />
        <path
          d="M96.4866 4.69727H95.5977C95.4309 4.69727 95.2949 4.83884 95.2949 5.01243V11.0327C95.2949 11.2063 95.4309 11.3478 95.5977 11.3478H96.4866C96.6534 11.3478 96.7894 11.2063 96.7894 11.0327V5.01243C96.7894 4.83884 96.6534 4.69727 96.4866 4.69727Z"
          fill="#122845"
        />
        <path
          d="M96.0414 3.67739C96.5434 3.67739 96.9498 3.25435 96.9498 2.73188C96.9498 2.20941 96.5434 1.78638 96.0414 1.78638C95.5395 1.78638 95.1331 2.20941 95.1331 2.73188C95.1331 3.25435 95.5395 3.67739 96.0414 3.67739Z"
          fill="#122845"
        />
        <path
          d="M69.1565 4.69727H68.2643C68.0975 4.69727 67.9631 4.83715 67.9631 5.01075V11.0343C67.9631 11.2079 68.0975 11.3478 68.2643 11.3478H69.1565C69.3232 11.3478 69.4576 11.2079 69.4576 11.0343V5.01075C69.4576 4.83715 69.3232 4.69727 69.1565 4.69727Z"
          fill="#122845"
        />
        <path
          d="M68.7113 3.67739C69.2133 3.67739 69.6197 3.25435 69.6197 2.73188C69.6197 2.20941 69.2133 1.78638 68.7113 1.78638C68.2094 1.78638 67.803 2.20941 67.803 2.73188C67.803 3.25435 68.2094 3.67739 68.7113 3.67739Z"
          fill="#122845"
        />
        <path
          d="M80.6737 11.9461V5.01075C80.6737 4.83715 80.5394 4.69727 80.3726 4.69727H79.4804C79.3136 4.69727 79.1793 4.83715 79.1793 5.01075V8.13715C79.1793 8.7085 79.053 9.18547 78.802 9.57142C78.551 9.95738 78.164 10.1495 77.6443 10.1495C77.3787 10.1495 77.1569 10.0973 76.9788 9.99277C76.8007 9.88828 76.6582 9.74839 76.5546 9.57142C76.4493 9.39614 76.3748 9.18378 76.3279 8.93603C76.2825 8.68828 76.2599 8.42198 76.2599 8.13715V5.01075C76.2599 4.83715 76.1255 4.69727 75.9587 4.69727H75.0665C74.8998 4.69727 74.7654 4.83715 74.7654 5.01075V8.64951C74.7654 9.10625 74.7945 9.48715 74.8544 9.79052C74.9143 10.0956 75.0212 10.3703 75.1767 10.6181C75.3596 10.8945 75.609 11.1136 75.9231 11.2737C76.2388 11.4355 76.6291 11.5164 77.0954 11.5164C77.5617 11.5164 77.9859 11.4237 78.3438 11.2383C78.7 11.0529 78.9785 10.7798 79.1793 10.4175V11.9445C79.1793 12.2579 79.1404 12.5158 79.0627 12.7147C78.9849 12.9152 78.8781 13.077 78.7405 13.2001C78.6028 13.3231 78.4393 13.4091 78.2466 13.4563C78.0863 13.4967 77.9098 13.5152 77.7236 13.522C77.6912 13.5254 77.6556 13.527 77.6167 13.527C77.5649 13.527 77.5131 13.5254 77.4629 13.5203C76.9885 13.4883 76.6695 13.3113 76.4688 13.1411C76.3603 13.0484 76.2016 13.0534 76.0931 13.1478L75.5847 13.586C75.4503 13.7006 75.4373 13.9113 75.5572 14.0428C75.902 14.422 76.5222 14.8416 77.5908 14.8416C78.5964 14.8416 79.359 14.604 79.8804 14.1287C80.4098 13.6349 80.6754 12.9068 80.6754 11.9461"
          fill="#122845"
        />
        <path
          d="M113.174 11.3459H114.063C114.229 11.3459 114.365 11.2043 114.365 11.0307V8.47738C114.365 7.63974 114.534 7.02794 114.872 6.64367C115.196 6.27457 115.659 6.08412 116.258 6.06895C116.352 6.06895 116.44 6.07569 116.49 6.08075C116.532 6.0858 116.577 6.08917 116.626 6.09423C116.788 6.11277 116.93 5.97962 116.93 5.8094V4.92457C116.93 4.78468 116.833 4.6667 116.7 4.64142C116.593 4.6212 116.474 4.61108 116.341 4.61108C116.13 4.61108 115.923 4.64142 115.717 4.70378C115.512 4.76614 115.319 4.85209 115.141 4.96165C114.963 5.0712 114.807 5.19423 114.675 5.33243C114.542 5.47063 114.438 5.61558 114.365 5.76726V5.00884C114.365 4.83693 114.231 4.69704 114.066 4.69704H113.172C113.007 4.69704 112.873 4.83693 112.873 5.00884V11.0307C112.873 11.2043 113.009 11.3459 113.175 11.3459"
          fill="#122845"
        />
        <path
          d="M37.6183 11.3459H38.5072C38.674 11.3459 38.81 11.2043 38.81 11.0307V8.47738C38.81 7.63974 38.9784 7.02794 39.3168 6.64367C39.6407 6.27457 40.1037 6.08412 40.7028 6.06895C40.7967 6.06895 40.8842 6.07569 40.9344 6.08075C40.9765 6.0858 41.0218 6.08917 41.0704 6.09423C41.2323 6.11277 41.3748 5.97962 41.3748 5.8094V4.92457C41.3748 4.78468 41.2776 4.6667 41.1449 4.64142C41.038 4.6212 40.9182 4.61108 40.7854 4.61108C40.5749 4.61108 40.3677 4.64142 40.162 4.70378C39.9564 4.76614 39.7637 4.85209 39.5856 4.96165C39.4075 5.0712 39.2521 5.19423 39.1193 5.33243C38.9865 5.47063 38.8829 5.61558 38.81 5.76726V5.00884C38.81 4.83693 38.6756 4.69704 38.5105 4.69704H37.6167C37.4515 4.69704 37.3171 4.83693 37.3171 5.00884V11.0307C37.3171 11.2043 37.4531 11.3459 37.6199 11.3459"
          fill="#122845"
        />
        <path
          d="M126.767 2.07456C126.691 1.98692 126.597 1.91781 126.485 1.86388C126.373 1.81163 126.249 1.78467 126.11 1.78467C125.97 1.78467 125.846 1.81163 125.734 1.86388C125.622 1.91613 125.528 1.98692 125.452 2.07456C125.376 2.1622 125.316 2.26332 125.276 2.37793C125.235 2.49253 125.214 2.61051 125.214 2.73186C125.214 2.85321 125.235 2.97119 125.276 3.08579C125.318 3.2004 125.376 3.30152 125.452 3.38748C125.528 3.47512 125.622 3.5459 125.734 3.59815C125.846 3.6504 125.97 3.67736 126.11 3.67736C126.249 3.67736 126.373 3.6504 126.485 3.59815C126.597 3.5459 126.691 3.47512 126.767 3.38748C126.845 3.29984 126.903 3.19871 126.943 3.08579C126.984 2.97119 127.005 2.85321 127.005 2.73186C127.005 2.61051 126.984 2.49253 126.943 2.37793C126.901 2.26332 126.843 2.1622 126.767 2.07456ZM126.78 3.03017C126.747 3.12624 126.699 3.2122 126.637 3.28467C126.576 3.35883 126.5 3.41781 126.411 3.46163C126.322 3.50545 126.221 3.52905 126.11 3.52905C125.998 3.52905 125.897 3.50714 125.807 3.46163C125.718 3.41781 125.642 3.35714 125.58 3.28467C125.519 3.21051 125.472 3.12624 125.438 3.03017C125.404 2.93411 125.389 2.83467 125.389 2.73186C125.389 2.62905 125.405 2.52961 125.438 2.43354C125.47 2.33748 125.519 2.25321 125.58 2.17905C125.642 2.10489 125.718 2.0459 125.807 2.0004C125.896 1.95658 125.996 1.93298 126.11 1.93298C126.223 1.93298 126.322 1.95489 126.411 2.0004C126.5 2.0459 126.576 2.10489 126.637 2.17905C126.699 2.25321 126.746 2.33748 126.78 2.43354C126.812 2.52961 126.83 2.62905 126.83 2.73186C126.83 2.83467 126.814 2.93411 126.78 3.03017Z"
          fill="#122845"
        />
        <path
          d="M126.417 2.76746C126.453 2.71353 126.47 2.64949 126.47 2.58038C126.47 2.53656 126.462 2.49443 126.448 2.45398C126.433 2.41353 126.412 2.37982 126.385 2.34949C126.357 2.31915 126.325 2.29555 126.286 2.2787C126.247 2.26185 126.203 2.25342 126.158 2.25342H125.802V3.20904H125.944V2.90398H126.121L126.304 3.20904H126.464L126.268 2.88207C126.333 2.86016 126.383 2.8214 126.419 2.76746M126.275 2.7169C126.242 2.75567 126.2 2.7742 126.147 2.7742H125.946V2.38151H126.147C126.2 2.38151 126.244 2.40173 126.275 2.4405C126.305 2.47926 126.322 2.52645 126.322 2.5787C126.322 2.63095 126.305 2.67814 126.275 2.71522"
          fill="#122845"
        />
        <path
          d="M11.1383 6.02013L15.8647 3.17856L15.7141 2.90721C15.617 2.73193 15.4016 2.67125 15.2332 2.77406L11.3002 5.13698V0.41114C11.3002 0.208893 11.1432 0.0454102 10.9489 0.0454102H10.6477V5.72687C10.6477 5.98811 10.9197 6.15159 11.1367 6.02013"
          fill="#7FC8E5"
        />
        <path
          d="M11.3457 6.98085L16.0721 9.82242L16.2226 9.55107C16.3198 9.37579 16.2615 9.15163 16.0947 9.05051L12.1617 6.68759L16.0947 4.32467C16.2631 4.22354 16.3198 3.99939 16.2243 3.82579L16.0737 3.55444L11.3473 6.39602C11.1303 6.52579 11.1303 6.85276 11.3473 6.98253"
          fill="#7FC8E5"
        />
        <path
          d="M9.58399 0.41114V5.13867L5.65101 2.77575C5.48262 2.67462 5.26727 2.7353 5.17011 2.90889L5.01953 3.18024L9.7459 6.02182C9.96287 6.15159 10.2349 5.98979 10.2349 5.72856V0.0454102H9.93373C9.73943 0.0454102 9.58237 0.208893 9.58237 0.41114"
          fill="#7FC8E5"
        />
        <path
          d="M10.6868 7.58604V13.2675H10.9879C11.1822 13.2675 11.3393 13.104 11.3393 12.9018V8.17593L15.2723 10.5389C15.4407 10.64 15.656 10.5793 15.7532 10.4057L15.9038 10.1344L11.1758 7.29278C10.9588 7.16301 10.6868 7.32481 10.6868 7.58604Z"
          fill="#7FC8E5"
        />
        <path
          d="M9.54016 6.3926L4.81379 3.55103L4.66321 3.82237C4.56606 3.99765 4.62435 4.22181 4.79112 4.32125L8.7241 6.68417L0.328673 11.7269C0.160279 11.828 0.103609 12.0521 0.200759 12.2274L0.351342 12.4988L9.54178 6.97743C9.75875 6.84766 9.75875 6.52069 9.54178 6.38923"
          fill="#122845"
        />
        <path
          d="M9.74732 7.2918L0.556885 12.8131L0.707468 13.0845C0.804619 13.2598 1.01997 13.3188 1.18836 13.2193L9.58379 8.17663V12.9025C9.58379 13.1047 9.74085 13.2682 9.93515 13.2682H10.2363V7.58674C10.2363 7.32551 9.96429 7.16203 9.74732 7.29349"
          fill="#122845"
        />
      </g>
      <defs>
        <clipPath id="clip0_1389_1532">
          <rect width="127.105" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
