export default function ProfileIcon() {
  return (
    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9383 11.178L16.2773 15.3698C14.2978 17.4074 11.5033 18.63 8.41775 18.63C5.27394 18.63 2.47944 17.4074 0.5 15.3698L1.89725 11.178H14.9383Z"
        fill="#065444"
      />
      <path
        d="M12.0273 6.28762C11.2705 7.62666 9.87322 8.3835 8.41775 8.3835C6.90406 8.3835 5.50681 7.62666 4.74997 6.28762C3.99313 5.00681 3.99313 3.43491 4.74997 2.09588C5.50681 0.815062 6.90406 0 8.41775 0C9.87322 0 11.2705 0.815062 12.0273 2.09588C12.7842 3.43491 12.7842 5.00681 12.0273 6.28762Z"
        fill="#065444"
      />
    </svg>
  );
}
