export default function PieIcon5050() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.0785"
        cy="15.0598"
        r="13"
        fill="#0A9373"
        fillOpacity="0.25"
      />
      <path
        d="M15.0785 2.05987C13.3713 2.05987 11.6808 2.39613 10.1036 3.04944C8.52637 3.70275 7.09326 4.66032 5.8861 5.86749C4.67893 7.07465 3.72136 8.50776 3.06805 10.085C2.41474 11.6622 2.07848 13.3527 2.07848 15.0599C2.07848 16.7671 2.41474 18.4575 3.06805 20.0348C3.72136 21.612 4.67893 23.0451 5.88609 24.2523C7.09325 25.4594 8.52636 26.417 10.1036 27.0703C11.6808 27.7236 13.3713 28.0599 15.0785 28.0599L15.0785 15.0599L15.0785 2.05987Z"
        fill="#0A9373"
      />
    </svg>
  );
}
