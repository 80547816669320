import { Link } from '@remix-run/react';
import { Button } from '../ui/button';
import { PageRoutes } from '~/constants/routes';
import { DropdownMenuItem } from '../ui/dropdown-menu';

export default function AuthActions({
  isAuthenticated,
  onCloseMenu,
  onLogout,
}: {
  isAuthenticated: boolean;
  onCloseMenu: () => void;
  onLogout: () => void;
}) {
  if (isAuthenticated) {
    return (
      <Button
        type="button"
        onClick={onLogout}
        className="flex h-12 w-full items-center justify-center rounded-full border border-green-dark bg-transparent text-base font-semibold text-green-dark"
      >
        Log out
      </Button>
    );
  }

  return (
    <div className="flex w-full flex-col items-center justify-center space-y-4">
      <DropdownMenuItem onClick={onCloseMenu} className="flex w-full focus:bg-transparent">
        <Link
          className="flex h-12 w-full items-center justify-center rounded-full border bg-green-dark text-base font-semibold text-beige-light"
          to={PageRoutes.SIGNUP}
        >
          Create your free account
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem onClick={onCloseMenu} className="flex w-full pt-0 focus:bg-transparent">
        <Link
          className="flex h-12 w-full items-center justify-center rounded-full border border-green-dark bg-transparent text-base font-semibold text-green-dark"
          to={PageRoutes.LOGIN}
        >
          Log in
        </Link>
      </DropdownMenuItem>
    </div>
  );
}
