export default function SummaryIcon() {
  return (
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.6875 0.5V1.4375V3H12.3125V1.4375V0.5H14.1875V1.4375V3H18.25V6.125V8V18.625V20.5H16.375H2.625H0.75V18.625V8V6.125V3H4.8125V1.4375V0.5H6.6875ZM16.375 8H2.625V18.625H16.375V8ZM10.6328 12.0625L13.6406 12.2969L11.3359 14.25L12.0391 17.1797L9.5 15.6172L6.92188 17.1797L7.625 14.25L5.32031 12.2969L8.32812 12.0625L9.5 9.25L10.6328 12.0625Z"
        fill="#065444"
      />
    </svg>
  );
}
