import { Link } from '@remix-run/react';
import { format, parse } from 'date-fns';
import { PageRoutes } from '~/constants/routes';

export default function Dates({ startDate, endDate }: { startDate: string; endDate: string }) {
  const formattedStartDate = startDate
    ? format(parse(startDate as string, 'MM/dd/yyyy', new Date()), 'MMM d, yyyy')
    : '';
  const formattedEndDate = endDate ? format(parse(endDate as string, 'MM/dd/yyyy', new Date()), 'MMM d, yyyy') : '';

  return (
    <>
      <div className="flex items-center justify-between px-4 pt-4">
        <h5 className="text-brown text-sm font-medium">Dates</h5>
        <Link className="text-brown text-xs font-medium uppercase underline" to={PageRoutes.SCHEDULE_DETAILS}>
          Edit
        </Link>
      </div>
      <div className="flex w-full items-center justify-between gap-x-4 px-4 pt-2">
        {[formattedStartDate, formattedEndDate].map((formattedDate, index) => (
          <div
            key={`${formattedDate}-${index}`}
            className="border-taupe flex h-14 w-1/2 flex-col items-center justify-center rounded-lg border border-dashed"
          >
            <span className="text-brown text-[10px] font-bold uppercase">{index ? 'End Date' : 'Start Date'}</span>
            <span className="text-green-primary-text text-base font-medium">{formattedDate}</span>
          </div>
        ))}
      </div>
    </>
  );
}
