export default function PieIcon6040() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.0785"
        cy="15.0598"
        r="13"
        fill="#8EA5DB"
        fillOpacity="0.25"
      />
      <path
        d="M15.0785 2.05987C13.0269 2.05987 11.0045 2.5454 9.17661 3.47678C7.34868 4.40816 5.76712 5.75893 4.56126 7.41866C3.3554 9.07838 2.55946 10.9999 2.23853 13.0262C1.9176 15.0525 2.08079 17.126 2.71475 19.0771C3.34871 21.0282 4.43544 22.8016 5.88609 24.2523C7.33675 25.7029 9.11013 26.7896 11.0613 27.4236C13.0124 28.0576 15.0859 28.2207 17.1121 27.8998C19.1384 27.5789 21.06 26.783 22.7197 25.5771L15.0785 15.0599L15.0785 2.05987Z"
        fill="#8EA5DB"
      />
    </svg>
  );
}
