import PieIcon5050 from '~/components/icons/PieIcon-5050';
import PieIcon6040 from '~/components/icons/PieIcon-6040';
import PieIcon7030 from '~/components/icons/PieIcon-7030';
import PieIcon8020 from '~/components/icons/PieIcon-8020';

export const categoryIconAndRoute = {
  1: { icon: <PieIcon5050 />, route: '/schedule/50-50' },
  2: { icon: <PieIcon6040 />, route: '/schedule/60-40' },
  3: { icon: <PieIcon7030 />, route: '/schedule/70-30' },
  4: { icon: <PieIcon8020 />, route: '/schedule/80-20' },
};
