export default function CalendarCheckIcon() {
  return (
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.6875 0.5V1.4375V3H12.3125V1.4375V0.5H14.1875V1.4375V3H18.25V6.125V8V18.625V20.5H16.375H2.625H0.75V18.625V8V6.125V3H4.8125V1.4375V0.5H6.6875ZM16.375 8H2.625V18.625H16.375V8ZM13.6016 12.1016L9.22656 16.4766L8.5625 17.1406L7.89844 16.4766L5.39844 13.9766L4.73438 13.3125L6.0625 12.0234L6.72656 12.6875L8.5625 14.5234L12.2734 10.8125L12.9375 10.1484L14.2266 11.4375L13.5625 12.1016H13.6016Z"
        fill="#065444"
      />
    </svg>
  );
}
