import { useLocation } from '@remix-run/react';
import { PageRoutes } from '~/constants/routes';

export default function useIsCalendarRoute() {
  const location = useLocation();
  return (
    [PageRoutes.CALENDAR_PREVIEW, PageRoutes.HOLIDAYS_LIST, PageRoutes.HOLIDAYS_ADD].includes(
      location.pathname as PageRoutes
    ) || location.pathname.indexOf(PageRoutes.HOLIDAYS_EDIT) > -1
  );
}
