export default function SecurityIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.3125 0.5H12.6875L13.3516 3.58594C13.9766 3.82031 14.5234 4.17188 15.0703 4.5625L18.0391 3.625L20.2266 7.41406L17.9219 9.52344C17.9609 9.83594 18 10.1875 18 10.5C18 10.8516 17.9609 11.1641 17.9219 11.5156L20.2266 13.625L18.0391 17.4141L15.0703 16.4375C14.5234 16.8672 13.9766 17.1797 13.3516 17.4531L12.6875 20.5H8.3125L7.60938 17.4531C7.02344 17.2188 6.4375 16.8672 5.89062 16.4766L2.92188 17.4141L0.734375 13.625L3.03906 11.5156C3 11.2031 3 10.8516 3 10.5C3 10.1875 3 9.83594 3.03906 9.52344L0.734375 7.41406L2.92188 3.625L5.89062 4.5625C6.4375 4.17188 7.02344 3.82031 7.60938 3.58594L8.3125 0.5ZM10.5 13.625C11.5938 13.625 12.6094 13.0391 13.1953 12.0625C13.7422 11.125 13.7422 9.91406 13.1953 8.9375C12.6094 8 11.5938 7.375 10.5 7.375C9.36719 7.375 8.35156 8 7.76562 8.9375C7.21875 9.91406 7.21875 11.125 7.76562 12.0625C8.35156 13.0391 9.36719 13.625 10.5 13.625Z"
        fill="#065444"
      />
    </svg>
  );
}
