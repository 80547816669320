export default function CaretRightIcon() {
  return (
    <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.44855 1.23615C2.05579 0.58679 3.10629 0.587954 3.71194 1.23964L11.839 9.36667C12.4918 9.97341 12.4918 11.0266 11.839 11.6333L3.71195 19.7604C3.1063 20.4121 2.05578 20.4132 1.44854 19.7638C0.799277 19.1567 0.800349 18.1064 1.45175 17.5007L8.43182 10.4793L1.45204 3.49955C0.800347 2.8939 0.799184 1.84339 1.44855 1.23615Z"
        fill="#B2A799"
        stroke="#EDDFCC"
        strokeWidth="0.5"
      />
    </svg>
  );
}
