export default function CalendarIcon() {
  return (
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.6875 1.4375V3H12.3125V1.4375V0.5H14.1875V1.4375V3H18.25V6.125V8V18.625V20.5H16.375H2.625H0.75V18.625V8V6.125V3H4.8125V1.4375V0.5H6.6875V1.4375ZM2.625 8V10.1875H5.75V8H2.625ZM2.625 12.0625V14.5625H5.75V12.0625H2.625ZM7.625 12.0625V14.5625H11.375V12.0625H7.625ZM13.25 12.0625V14.5625H16.375V12.0625H13.25ZM16.375 10.1875V8H13.25V10.1875H16.375ZM16.375 16.4375H13.25V18.625H16.375V16.4375ZM11.375 16.4375H7.625V18.625H11.375V16.4375ZM5.75 16.4375H2.625V18.625H5.75V16.4375ZM11.375 10.1875V8H7.625V10.1875H11.375Z"
        fill="#065444"
      />
    </svg>
  );
}
